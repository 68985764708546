﻿/// <reference path="../_Variables.less" />

@media (min-width: @screen-sm-min) {
    .pull-right-sm {
        float: right;
    }

    .clear-left-sm {
        clear:left;
    }
}
