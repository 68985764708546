﻿/// <reference path="../_Variables.less" />

.gototop {
    display: inline-block;
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 50px;
    right: 20px;
    /* image replacement properties */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s 0s, visibility 0s .3s;
    -moz-transition: all .3s 0s, visibility 0s .3s;
    -o-transition: all .3s 0s, visibility 0s .3s;
    transition: all .3s 0s, visibility 0s .3s;
    background-color: @color-brand-primary-1;

    border-radius:5px;

    .default-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 14px;
        top: 17px;
    }
}

.gototop.gototop-is-visible, .gototop.gototop-fade-out, .no-touch .gototop:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    -o-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
}

.gototop.gototop-is-visible {
    /* the button becomes visible */
    visibility: visible;
    opacity: 1;
}

.gototop.gototop-fade-out {
    /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
    opacity: .5;
}


.gototop:hover {
    background-color: @color-brand-primary-2;
    opacity: 1;
}

