﻿/// <reference path="../_Variables.less" />
/// <reference path="../Mixins.less" />

@button-border-radius: 5px;

@button-fill-color: @color-brand-primary-1;
@button-border-color: @color-brand-primary-1;
@button-text-color: contrast(@color-brand-primary-1, @color-text-accent-1,@color-text-accent-2);

.button , .btn {
   
    .border-radius(@button-border-radius);
    text-decoration: none;

    @icon-size: 16px;

    display: inline-block;
    position: relative;
    vertical-align: middle;
    padding: 0;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &.button-default , &.btn-default {
       // line-height: 28px;

        & {
            &:hover, &:active, &:focus {
                background-color: @color-brand-primary-2;
                border-color: @color-brand-primary-2;
            }

            .box-shadow & {
                &:focus {
                    -webkit-box-shadow: 0 0 5px 1px fadeout(darken(@color-brand-primary-1,50%),50%);
                    -moz-box-shadow: 0 0 5px 1px fadeout(darken(@color-brand-primary-1,50%),50%);
                    box-shadow: 0 0 5px 1px fadeout(darken(@color-brand-primary-1,50%),50%);
                }
            }

            .no-box-shadow & {
                &:focus {
                    outline: auto;
                }
            }
        }

        background-color: @button-fill-color;
        border: 0;
        font-weight: bold;
        min-height:44px;
        padding: 0 @padding-default;

        &, &:visited {
            color: @button-text-color;
        }

        &.button-continue, &.button-with-icon {
            padding-right: @padding-default + @icon-size + @padding-default;
        }

        &.button-continue {
            .button-icon {
                right: @padding-default;
            }
        }

        &.button-with-icon-left {
            padding-left: @padding-default + @icon-size + @padding-default;

            .button-icon {
                left: @padding-default;
            }
        }

        
    }

    &.button-icon {
        background-color: transparent;
        border: 0;


        &:hover, &:active, &:focus {
            svg {
                .fillSvg (@color-brand-primary-1);
            }
        }
    }

    .button-icon {
        position: absolute;
        top: 50%;
        margin-top: -(@icon-size / 2);
        width: @icon-size;
        height: @icon-size;


        svg {
            display: block;
            width: @icon-size;
            height: @icon-size;
        }
    }
    
    &.button-text {
        border: 0;
        background: none;
        color: @color-brand-primary-1;;
        text-decoration: underline;
        .fontSize(14);

        &:hover, &:focus {
            color: @color-brand-primary-1;
        }
    }

}


a {
    cursor: pointer;
}

.btn {
    padding: 0 @padding-default;
    line-height: 22px;
    height: 24px;
}
