﻿/// <reference path="../_Variables.less" />
/// <reference path="../Bootstrap.less" />
/// <reference path="../Layout/Body.less" />

.carousel-container {
    background: #E3E3E3;
}

.jumbo-carousel {
    margin: 0 auto;
    max-width: 2000px;
	p{
		margin-top: 1.6667em;
		margin-bottom: 0.0000em;
	}
    .carousel-indicators {
        bottom: 2px;
        margin: 0 auto;
        left: 0;
        right: 0;

        @media screen and (min-width:@medium_breakpoint-min) {
            bottom: 10px;
        }

        li {
            background: @color-text-accent-2;
            border-color: @color-text-accent-2;

            &.active {
                background: @color-brand-primary-1;
                border-color: @color-brand-primary-1;
            }
        }
    }
}

.header-carousel-caption {
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 80%;
    width: 100%;
    top: 0;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em; /* Adjusts for spacing */
    }

    .carousel-caption-helper {
        display: inline-block;
        vertical-align: middle;
    }

    @media screen and (min-width:@medium_breakpoint-min) {
        padding-bottom: 20px;
    }

    @media screen and (min-width:@large_breakpoint-min) {
        max-width: 950px;
    }

    margin: 0 auto;
    text-align: left;
    text-shadow: none;

    p {

        @media screen and (min-width:@medium_breakpoint-min) {
            line-height: 2;
        }



        span {
            display: inline-block;
            background: fadeout(@color-background-level-2,50%);
            line-height: 1.5;

            @media screen and (min-width:@medium_breakpoint-min) {
                max-width: 30em;
            }

            strong {
                @media screen and (min-width:@medium_breakpoint-min) {
                    font-size: 30px;
                }
            }
        }

        span, > a {
            padding: 5px 10px;

            @media screen and (min-width:@medium_breakpoint-min) {
                padding: 10px;
            }
        }

        > a {
            background: @color-brand-primary-1;
            text-decoration: none;
            color: @color-text-accent-2;
            font-weight: bold;

            &:hover, &:focus {
                background: @color-brand-primary-2;
            }
        }
    }
}

.content-carousel {
    
    
    width: 100%;
    margin:30px 0;

    .carousel-indicators {
        margin: 10px 0 0;
        overflow: auto;
        position: static;
        text-align: left;
        white-space: nowrap;
        width: 100%;
    }

    .carousel-indicators li {
        background-color: transparent;
        -webkit-border-radius: 0;
        border-radius: 0;
        display: inline-block;
        height: auto;
        margin: 0 !important;
        width: auto;
    }

    .carousel-indicators li img {
        display: block;
        opacity: 0.5;
    }

    .carousel-indicators li.active img {
        opacity: 1;
    }

    .carousel-indicators li:hover img {
        opacity: 0.75;
    }

    .carousel-outer {
        position: relative;
    }
}

/*
.content-carousel {
    height:600px;
    position:relative;

    .content-carousel-slides {
        cursor:move;
        height:500px;
        width:100%;
        position:absolute;
        top:0;
        left:0;
    }

    .jssort01 {
        position:absolute;
        .p {
            position:absolute;
            width:60px;
            height:60px;
        }

        .w {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }

        .t {
                position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
        }

        .c {
                position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    border: #000 2px solid;
    box-sizing: content-box;

    // background???
        }
    }
}*/
