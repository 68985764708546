﻿/// <reference path="../_Variables.less" />

.img-block {
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (min-width:@medium_breakpoint-min) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.first-image{
	margin-top: 40px;

	@media screen and (max-width: @small_breakpoint-max){
		.img-responsive {
			max-height:250px;
			margin:0 auto;
		}
	}

}
/*
@media screen and (max-width:500px){
	.first-image{
		width:80%;
	}
}*/