﻿/// <reference path="../_Variables.less" />
/// <reference path="../Bootstrap.less" />

.title-project{
	margin-left:10px;
}
.pagination-container {
    text-align: center;
	.pagination {
		.current {
			color:#fff;
			background-color:@color-brand-primary-1;
		}
	}
}

.container-projects {
    margin-top: 30px;

    @media screen and (min-width:@medium_breakpoint-min) {
        .row {
            margin: 0;
        }
    }

    .col-xs-6, .col-sn-4, .col-md-3 {
        padding: 0;
    }
	.project-tile{
		padding: 10px;
	}
}

.project-preview {
    display: block;
    position: relative;


    .project-title {
        position: absolute;
        font-weight: normal;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        display: block;
        background: fadeout(@color-brand-primary-1, 20%);
        font-size: 0.8em;


        @media screen and (min-width:@medium_breakpoint-min) {
            font-size: 1em;
            top: 0;
            opacity: 0;
            .transition(opacity 0.3s ease-in-out);
            font-weight: bold;
        }
    }

    &:hover, &:focus {
        .project-title {
            opacity: 1;
        }
    }

    .project-title-preview {
        width: 100%;
        height: 100%;
        display: table;
        color: #fff;


        .project-title-preview-helper {
            width: 100%;
            height: 100%;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            padding: 0.3em;
        }
    }

    .project-no-images {
        background: @color-brand-primary-1;
        min-width: 100%;
        /*min-height: 200px;*/
        position: relative;
        padding-top:75%;

        &:hover {
            background: @color-brand-primary-2;
        }

        .project-title {
            opacity: 1;
            background:none;
            color: @color-text-accent-2;
            text-align: center;
            top:45%;
        }
    }
}

.project-banner{
    text-decoration:none;
    &:hover{
        text-decoration:none;
    }
}