﻿.content-nav-link {
    margin-top: 0.5em;
}


.pagination {
    > li {

        > a, > span {
            min-width: 44px;
            line-height:44px;
            vertical-align:middle;
            padding-top:0;
            padding-bottom:0;
        }
    }
}
