﻿/// <reference path="../_Variables.less" />
html {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body{
	background-color:#E3E6F2;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.img-responsive, .img-responsive img {
    .img-responsive();
}

.container-maxed {
    max-width: 1278px;
}

.hidden-by-default {
    display:none;
}

.content-wrapper{
	max-width:2000px;
	margin:0 auto;
    background-color:#EFEFEF;
}
/*@media screen and (min-width: 2000px){
	.content-wrapper{
		background-color: #E3E6F2;
	}
}*/