﻿/// <reference path="../_Variables.less" />

@header-links-hover-color: contrast( @color-background-level-2,lighten(@color-text-accent-1, 20% ),darken(@color-text-accent-1, 20% ));

.header {

    a {
        color: @color-text-accent-1;
        text-decoration: none;

        &:hover, &:focus {
            color: @header-links-hover-color;

            path {
                fill: @header-links-hover-color;
            }
        }

        picture {
            img {
                height: 120px;
            }
        }
    }

    .header-logo {
        /*left:20px;*/
        padding-left: 20px;
    }
}

.header-container {
    background: @color-background-level-2;
    color: @color-text-accent-1;
    // height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media screen and (min-width:@large_breakpoint-min) {
        height: 140px;
    }
}

.header-icon {
    width: 24px;
    height: 24px;
}

@media screen and (min-width:@large_breakpoint-min) {
    .header-col {
        display: table;
    }
}

@media screen and (max-width: @medium_breakpoint-min) {
    .header-col {
        padding: 0;
    }
}

.header-element {
    height: 120px; // 100px?
    display: table-cell;
    vertical-align: middle;

    p {
        margin: 0;
    }
}

// different parts of the header


// phone

.header-phone {
    text-align: right;

    p {
        font-weight: bold;
        font-size: 24px;
    }

    @media screen and (min-width: @medium_breakpoint-min) and (max-width: (@medium_breakpoint-min + 100px)) {
        padding-left: 40px;
    }
}

// language select

.header-language {
    text-align: right;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;

    @media screen and (min-width:500px) {
        font-size: 18px;
    }

    @media screen and (max-width: @medium_breakpoint-min) {
        .header-element {
            width: 45vw;
        }
    }

    @media screen and (min-width:500px) and (max-width: @medium_breakpoint-min) {
        padding-right: 20px;
    }

    label {
        position: relative;

        &:hover, &:hover:after {
            color: darken(@color-text-accent-2, 20%);
            cursor: pointer;
        }

        margin-bottom: 0;

        &:before {
            font-family: 'Glyphicons Halflings';
            content: '\e114';
            display: block;
            position: absolute;
            bottom: 4px;
            right: 0;
            top: auto;
            margin-right: -0.75em;
            color: @color-text-accent-2;
            font-size: 10px;

            @media screen and (min-width:500px) {
                margin-right: 0;
                font-size: 0.75em;
            }

            @media screen and (min-width:@large_breakpoint-min) {
                top: 6px;
            }
        }
    }

    .change-language-text {

        @media screen and (max-width:@small_breakpoint-max) {
            display: block;
            text-align: left;
        }
    }

    .header-language-select {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        background: @color-background-level-2;
        color: @color-text-accent-2;
        border: 0;

        &:hover {
            color: darken(@color-text-accent-2, 20%);
            cursor: pointer;
        }

        font-size: 12px;

        @media screen and (min-width:500px) {
            font-size: 18px;
        }
    }

    .header-selected-language {
        color: @color-text-accent-2;
        font-size: 12px;
        display: inline-block;
        padding-right: 1.5em;



        &:hover {
            color: darken(@color-text-accent-2, 20%);
            cursor: pointer;
        }

        @media screen and (min-width:500px) {
            font-size: 18px;
        }
    }
}
