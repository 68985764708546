﻿/// <reference path="../_Variables.less" />

.footer {
    margin-top:30px;
    background: @color-background-level-3;
    .footer-content {
        padding: 0;// 30px 0 0 0;
        
        color: #fff;       

        a {
            &.block-link  {
                color:inherit;
            }
            color: #fff;
            text-decoration: none;
            font-weight: bold;

            &:hover, &:focus {
                color: lighten(@color-brand-primary-1,20%);
            }
        }
		.contact-data{
			a{
				font-weight:normal;
			}
		}
    }

    .footer-nav {
        margin-top:@padding-medium;
        background: @color-background-level-4;

        &, a {
            color: #999999;
            text-decoration: none;
        }

        a {
            display: inline-block;

            &:hover, &:focus {
                color: #fff;

                path {
                    fill: #fff;
                }
            }
        }
    }

    .footer-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .footer-nav-icon {
        width: 24px;
        height: 24px;
    }

    h3 {
        margin-bottom: 30px;
        padding-bottom: 24px;
        border-bottom: 1px solid #666666;
        color: @color-text-accent-2;
    }

    @media screen and (min-width:@medium_breakpoint-min) {
        .internal-links-container {
            text-align: right;
        }
    }

    .footer-bar-links {
        vertical-align: bottom;
        margin: 0;
        list-style: none;
        padding: 0;
        min-height: 60px;
        display: inline-block;
        
        li {
            float: left;
            margin: 0;
            margin-right: 30px;
            line-height: 60px;
        }

        &.internal-links {
            @media screen and (min-width:@medium_breakpoint-min) {
                text-align: right;


                li {
                    margin-left: 30px;
                    margin-right: 0;
                }
            }
        }
    }
}
