﻿/// <reference path="../_Variables.less" />

body {
    font-family: @base_font-family;
    font-size: @base_font-size;
}

p, ul, dl, .paragraph {
    font-size: 1em; /* 20px */
    line-height: 1.5000em; /* 30px */
    margin: 0;
}

.paragraph {
    max-width:35em;
}

.paragraph-small {
    font-size: 0.9000em; /* 18px */
    line-height: 1.6667em; /* 30px */
    /*margin-top: 1.6667em;*/
    /*margin-bottom: 0.0000em;*/
}

h1, .heading-1 {
    font-size: 1.5000em; /* 30px */
    line-height: 1.3333em; /* 40px */
    margin-top: 1.3333em;
    margin-bottom: 0.0000em;
}

h2, .heading-2 {
    font-size: 1.0909em; /* 24px */
    line-height: 1.3333em; /* 32px */
    margin-top: 1.3333em;
    margin-bottom: 0.0000em;
}

h3, .heading-3 {
    font-size: 1.0000em; /* 20px */
    line-height: 1.5000em; /* 30px */
    margin-top: 1.5000em;
    margin-bottom: 0.0000em;
}

h1, h2, h3, h4, h5, h6 {
    color: @color-brand-primary-2;
}

@media screen and (min-width:@medium_breakpoint-min) and (max-width:@medium_breakpoint-max) {
    body {
        font-size: @medium_font-size;
    }

    p, ul, dl, .paragraph {
        font-size: 1em; /* 18px */
        line-height: 1.6667em; /* 30px */
        /*margin: 0 0 1.6667em 0;*/
    }

    h1, .heading-1 {
        font-size: 1.6667em; /* 30px */
        line-height: 1.3333em; /* 40px */
        margin-top: 1.3333em;
        margin-bottom: 0.0000em;
    }

    h2, .heading-2 {
        font-size: 1.3333em; /* 24px */
        line-height: 1.2500em; /* 30px */
        margin-top: 1.2500em;
        margin-bottom: 0.0000em;
    }

    h3, .heading-3 {
        font-size: 1.1111em; /* 20px */
        line-height: 1.5000em; /* 30px */
        margin-top: 1.5000em;
        margin-bottom: 0.0000em;
    }

    .paragraph-small {
        font-size: 0.8889em; /* 16px */
        line-height: 1.8750em; /* 30px */
        /*margin-top: 1.8750em;*/
        /*margin-bottom: 0.0000em;*/
    }
}


@media screen and (min-width:@large_breakpoint-min) {
    body {
        font-size: @large_font-size;
    }

    p, ul, dl, .paragraph {
        font-size: 1em; /* 16px */
        line-height: 1.6250em; /* 26px */
        /*margin: 0 0 1.6250em 0;*/
    }



    h1, .heading-1 {
        font-size: 1.8750em; /* 30px */
        line-height: 1.7333em; /* 52px */
        margin-top: 0.8667em;
        margin-bottom: 0.0000em;
    }

    h2, .heading-2 {
        font-size: 1.2500em; /* 20px */
        line-height: 1.3000em; /* 26px */
        margin-top: 1.3000em;
        margin-bottom: 0.0000em;
        // font-size: 1.5000em; /* 24px */
        //  line-height: 1.0833em; /* 26px */
        //  margin-top: 1.0833em;
        // margin-bottom: 0.0000em;
    }

    h3, .heading-3 {
        font-size: 1.2500em; /* 20px */
        line-height: 1.3000em; /* 26px */
        margin-top: 1.3000em;
        margin-bottom: 0.0000em;
    }


    .paragraph-small {
        font-size: 0.8750em; /* 14px */
        line-height: 2.1429em; /* 30px */
        /*margin-top: 2.1429em;*/
        /*margin-bottom: 0.0000em;*/
    }
}


a {
    color: @color-brand-primary-1;
    text-decoration: none;
    font-weight: bold;

    &:hover, &:focus {
        text-decoration: underline;
    }

    &.block-link {
        color: inherit !important;
        font-weight: inherit !important;
        display:block;

        .link {
            color: @color-brand-primary-1;
            font-weight:bold;
        }

        &:hover, &:focus {
            h1, h2, h3, h4, h5, h6, .hover {
                color: @color-brand-primary-1;
            }

            .link {
                color: lighten(@color-brand-primary-1,20%);
            }
        }
    }
}
